import { NextPageWithLayout } from "@bptypes/layout";
import { FacetFilter } from "@components/filters";
import {
	ALLOWED_VIEW_TYPES,
	HeadingWithBreadcrumb,
} from "@components/headings/HeadingWithBreadcrumb";
import { TracksList } from "@components/lists/TracksList";
import { Pager, urlParamsToResetPagination } from "@components/paging";
import { PER_PAGE_OPTIONS } from "@components/paging/Pager/Pager";
import { TracksTable } from "@components/tables/TracksTable";
import MainLayout from "@layouts/MainLayout";
import { exportAnonSession, useSessionContext } from "@lib/context/session";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { getLabelQuery } from "@lib/network/labels";
import { getTracksQuery } from "@lib/network/tracks";
import { getSafeDefaultPageParam, getSafeDefaultPerPageParam } from "@lib/utils";
import { getLocationData } from "@lib/utils/getLocationData";
import { Label } from "@models/label";
import { device } from "@styles/theme";
import { QueryClient, dehydrate, useQuery } from "@tanstack/react-query";
import { i18n } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";

interface Props {
	label?: Label;
	anonSession: AnonSession;
}

const LabelTracks: NextPageWithLayout<Props> = ({ label, anonSession }) => {
	const router = useRouter();
	const isDesktop = useMediaQuery({ query: device.xl });
	const { getAccessToken, importAnonSession } = useSessionContext();
	importAnonSession(anonSession);
	const accessToken = getAccessToken();

	const {
		page,
		per_page,
		order_by,
		preorders,
		sub_genre_id,
		bpm,
		publish_date,
		artist_id,
		genre_id,
		key_id,
	} = router.query;

	const [queryParams, setQueryParams] = useState({
		page: getSafeDefaultPageParam(page),
		per_page: getSafeDefaultPerPageParam(per_page, PER_PAGE_OPTIONS[0]),
		order_by: order_by ? order_by.toString() : "-release_date,release_id",
		preorder: preorders ? preorders === "true" : false,
		genre_id: genre_id ? genre_id.toString() : "",
		include_facets: true,
		bpm: bpm ? bpm.toString() : "",
		publish_date: publish_date ? publish_date.toString() : "",
		sub_genre_id: sub_genre_id ? sub_genre_id.toString() : "",
		artist_id: artist_id ? artist_id.toString() : "",
		label_id: label?.id.toString() || "",
		key_id: key_id ? key_id.toString() : "",
	});

	useEffect(() => {
		setQueryParams({
			...queryParams,
			page: getSafeDefaultPageParam(page),
			per_page: getSafeDefaultPerPageParam(per_page, PER_PAGE_OPTIONS[0]),
			preorder: preorders ? preorders === "true" : false,
		});
	}, [page, per_page, preorders]);

	const tracksQuery = useQuery(getTracksQuery({ params: queryParams, accessToken }));

	const handleFacetFilterChange = (data: Record<string, string>) => {
		router.replace(
			{
				query: {
					...router.query,
					...data,
				},
			},
			undefined,
			{ shallow: true },
		);
		setQueryParams({
			...queryParams,
			...data,
			...urlParamsToResetPagination(router),
		});
	};

	const handleFacetFilterReset = () => {
		const path = router.asPath.split("?")[0];
		router.push(path);

		setQueryParams({
			page: 1,
			per_page: 25,
			order_by: "-release_date,release_id",
			preorder: false,
			genre_id: "",
			include_facets: true,
			bpm: "",
			publish_date: "",
			sub_genre_id: "",
			artist_id: "",
			label_id: label?.id.toString() || "",
			key_id: "",
		});
	};

	return (
		<>
			<HeadingWithBreadcrumb
				location={`Label - ${label?.name} - Tracks`}
				id={label?.id || 0}
				slug={label?.slug || ""}
				viewType={ALLOWED_VIEW_TYPES.LABEL}
				title={label?.name || ""}
				tracks={tracksQuery.data ? tracksQuery.data.results : []}
				showFollow
				showPlay
				showAddToQueue
			/>

			{queryParams.include_facets && (
				<FacetFilter
					hide={["label"]}
					facets={tracksQuery.data?.facets?.fields}
					onChange={handleFacetFilterChange}
					onReset={handleFacetFilterReset}
					defaults={queryParams}
					showBPMFilter
					showReleaseFilter
				/>
			)}

			{isDesktop && (
				<Pager
					totalResults={tracksQuery.data?.count}
					showPreordersFilter
				/>
			)}

			{isDesktop ?
					(
						<TracksTable
							location={`Label Page - ${label?.name} - Tracks Tab`}
							showNumbers={false}
							isLoading={tracksQuery.isLoading}
							tracks={tracksQuery.data ? tracksQuery.data.results : []}
						/>
					) :
					(
						<TracksList
							location={`Label Page - ${label?.name} - Tracks Tab`}
							isLoading={tracksQuery.isLoading}
							tracks={tracksQuery.data ? tracksQuery.data.results : []}
							showArtwork
						/>
					)}

			<Pager
				totalResults={tracksQuery.data?.count}
				showPreordersFilter
			/>
		</>
	);
};

export default LabelTracks;

LabelTracks.getLayout = (page: ReactElement<Props>) => {
	const labelImage: string = (page.props.label?.image.uri || "");
	return (
		<MainLayout
			title={i18n?.t("MusicDownload", { name: page.props.label?.name })}
			metaDescription={
				page.props.label?.bio ?
					page.props.label?.bio :
					i18n?.t("ExploreComplexTracks", { name: page.props.label?.name })
			}
			hasDesktopSidebar={true}
			socialImage={labelImage}
		>
			{page}
		</MainLayout>
	);
};

export async function getServerSideProps(ctx: any) {
	const queryClient = new QueryClient();
	const location = getLocationData(ctx.req);

	const {
		page,
		per_page,
		order_by,
		preorders,
		sub_genre_id,
		bpm,
		publish_date,
		artist_id,
		genre_id,
		key_id,
	} = ctx.query;

	const { id } = ctx.params;
	const labelId = id ? parseInt(id as string, 10) : 0;
	let label: Label | null = null;

	if (labelId > 0) {
		const data = await Promise.all([
			queryClient
				.fetchQuery<Label>(getLabelQuery({ labelId, location }))
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getTracksQuery({
						params: {
							page: getSafeDefaultPageParam(page),
							per_page: getSafeDefaultPerPageParam(per_page, PER_PAGE_OPTIONS[0]),
							order_by: order_by ?
								order_by.toString() :
								"-release_date,release_id",
							preorder: preorders ? preorders === "true" : false,
							genre_id: genre_id ? genre_id.toString() : "",
							include_facets: true,
							bpm: bpm ? bpm.toString() : "",
							publish_date: publish_date ? publish_date.toString() : "",
							sub_genre_id: sub_genre_id ? sub_genre_id.toString() : "",
							artist_id: artist_id ? artist_id.toString() : "",
							label_id: labelId.toString() || "",
							key_id: key_id ? key_id.toString() : "",
						},
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
		]);

		label = data[0] as Label;
	}

	const anonSession = await exportAnonSession();

	return {
		notFound: !label,
		props: {
			dehydratedState: dehydrate(queryClient),
			anonSession,
			...(await serverSideTranslations(ctx.locale, ["translation"])),
			label: label,
		},
	};
}
